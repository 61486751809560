import React from "react";
import favicon from '~image/favicon.png';
import Header from "../Header";
export default function Layout({
  children,
}) {
  return (
    <>
          <title>Arc PR &amp; Communications / above and beyond</title>
          <link rel="icon" type="image/png" href={favicon} />
      <Header/>
      {children}
    </>
  )
}
